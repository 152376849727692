import { useCallback, useEffect, useMemo, useState } from 'react';
import semiver from 'semiver';
import { AnimateChangeInHeight } from '@/components/AnimateChangeInHeight';
import { CenteredContent } from '@/features/magic/components/CenteredContent';
import { EntryList } from '@/features/magic/components/EntryList';
import { PromptForm } from '@/features/magic/components/PromptForm';
import { ResultsFeedbackForm } from '@/features/magic/components/ResultsFeedbackForm';
import { useQuickCreateContext } from '@/features/magic/contexts/QuickCreateContext';
import { MotionFlex, Text } from '@/ui';
import { Divider } from '../components/Divider';
import { ResetButton } from '../components/ResetButton';
import { ScreenshotActive } from '../components/ScreenshotActive';
import { ScreenshotButton } from '../components/ScreenshotButton';
import { IN_PANEL_SCREENSHOTS_MIN_VERSION } from '../constants';
import { usePostMessageReceiver } from '../hooks/usePostMessageReceiver';
import { getExtensionVersion } from '../utils/getExtensionVersion';

type Props = {
  onCreateAnother: () => void;
};

export const DefaultFlow = ({ onCreateAnother }: Props) => {
  const { prompt, createEntries, state, createMutation } =
    useQuickCreateContext();
  const [screenshotActive, setScreenshotActive] = useState(false);

  const supportsInPanelScreenshotRequests = useMemo(() => {
    const version = getExtensionVersion();
    return !!version && semiver(version, IN_PANEL_SCREENSHOTS_MIN_VERSION) > -1;
  }, []);

  const handleSubmit = () => {
    createEntries(prompt);
  };

  // notify the extension when the user requests to take a screenshot
  const handleRequestScreenshot = () => {
    window.parent.postMessage('_ah_screenshot_request', '*');
    setScreenshotActive(true);
  };

  // notify the extension when the user cancels the request to take a screenshot
  const handleCancelScreenshot = useCallback(() => {
    window.parent.postMessage('_ah_screenshot_cancel', '*');
    setScreenshotActive(false);
  }, []);

  // handle requests to cancel screenshots from the content script
  usePostMessageReceiver('_ah_screenshot_cancel', () => {
    setScreenshotActive(false);
  });

  // cancel the screenshot request on escape key press
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && screenshotActive) {
        handleCancelScreenshot();
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [screenshotActive, handleCancelScreenshot]);

  return (
    <MotionFlex
      animate={{ opacity: 1 }}
      direction="column"
      flex="1"
      initial={{ opacity: 0 }}
    >
      {state !== 'edit' || createMutation.isError ? (
        <>
          <CenteredContent>
            {supportsInPanelScreenshotRequests && (
              <AnimateChangeInHeight>
                {screenshotActive ? (
                  <ScreenshotActive onCancel={handleCancelScreenshot} />
                ) : (
                  <ScreenshotButton
                    isDisabled={state === 'loading'}
                    onClick={handleRequestScreenshot}
                  />
                )}
                <Divider py="4" />
              </AnimateChangeInHeight>
            )}
            <PromptForm submitButtonAlwaysVisible onSubmit={handleSubmit} />
            <Text fontSize="sm" mt="6" textAlign="center">
              💡 <strong>Tip:</strong> You can ask for multiple events!
            </Text>
          </CenteredContent>
        </>
      ) : (
        <CenteredContent gap="6" pt="1">
          <EntryList />
          <ResultsFeedbackForm />
          <ResetButton onClick={onCreateAnother} />
        </CenteredContent>
      )}
    </MotionFlex>
  );
};
