import { MaintenanceMode } from '@/features/magic/components/MaintenanceMode';
import { QuickCreateContextProvider } from '@/features/magic/contexts/QuickCreateContext';
import { QuickCreateExtension } from './QuickCreateExtension';

export const QuickCreateExtensionLoader = () => {
  return (
    <MaintenanceMode>
      <QuickCreateContextProvider>
        <QuickCreateExtension />
      </QuickCreateContextProvider>
    </MaintenanceMode>
  );
};
