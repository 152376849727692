import { useQuickCreateContext } from '@/features/magic/contexts/QuickCreateContext';
import {
  areTextPromptsEqual,
  ensurePrompt,
  isPromptLike,
  isPromptValid,
} from '@/features/magic/utils/prompt';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { Sentry } from '@/lib';
import { logger } from '@/lib/logger';
import { usePostMessageReceiver } from './usePostMessageReceiver';

export const useCreateOnPageLoad = () => {
  const {
    state,
    setPrompt,
    createEntries,
    prompt: existingPrompt,
  } = useQuickCreateContext();
  const { value: disableAllAiFeatures } = useFeatureFlag(
    'disableAllAiFeatures'
  );

  usePostMessageReceiver('_ah_set_prompt', (message) => {
    try {
      const prompt = JSON.parse(message);

      // The extension sends over a `Partial<Prompt>` type but the rest of the
      // app assumes a complete `Prompt` type. Therefore, for completeness
      // sake, we fill out any missing properties with default values before
      // threading it through the system. This also removes the complexity
      // of having to deal with partial prompts in the rest of the app.
      //
      // If we don't ensure a complete prompt then the only time a problem
      // could arise is if the app assumes that the `prompt` has a `file`
      // property (which does not exist in the extension and is therefore
      // never sent), or the app assumes a `base64` property (which does not
      // exist for users using a version of the extension before screenshot
      // support was released).
      if (isPromptLike(prompt)) {
        const completePrompt = ensurePrompt(prompt);

        if (!isPromptValid(completePrompt)) {
          throw new Error('Extension prompt is invalid');
        }

        if (
          areTextPromptsEqual(completePrompt, existingPrompt) &&
          state === 'loading'
        ) {
          logger.info(
            'Ignoring set prompt command because the requested prompt is the same as the in-process prompt',
            { currentPrompt: existingPrompt, nextPrompt: completePrompt }
          );
          return;
        }

        setPrompt(completePrompt, 'post-message');

        if (!disableAllAiFeatures) {
          logger.info('Creating quick create job on page load', { prompt });
          createEntries(completePrompt);
        }
      } else {
        throw new Error('Extension prompt is not prompt-like');
      }
    } catch (originalError) {
      const err = new Error('Error parsing prompt from extension');
      err.cause = originalError;
      Sentry.captureException(err, {
        extra: {
          prompt: message,
        },
      });
    }
  });
};
